<template>
    <NotificationWrapper>
        <Icon name="check_circle" class="absolute top-1 left-1 text-green-700" />
        {{ message }}
        <NotificationClose @notification-close="emit('notificationClose', id)" />
    </NotificationWrapper>
</template>

<script setup lang="ts">
    import type { Props } from '../props'

    defineProps<Props>()

    const emit = defineEmits<{
        notificationClose: [index: number]
    }>()
</script>
